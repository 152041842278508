import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ShopSettingsContext } from "../store/ShopSettingsProvider";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const { shopSettings } = useContext(ShopSettingsContext);

  const lngs = {
    en: { nativeName: "English" },
    it: { nativeName: "Italiano" },
  };

  const copyright = shopSettings.topBarTitle
    ? shopSettings.topBarTitle + " - " + t("copyright", { year: "2021" })
    : t("copyright", { year: "2021" });
  return (
    <footer className="my-16">
      <Link to="/contacts" className="underline">
        {t("contactUs")}
      </Link>
      <div>
        {Object.keys(lngs).map((lng) => (
          <React.Fragment key={lng}>
            <button
              className={i18n.language === lng ? "font-semibold" : "normal"}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng].nativeName}
            </button>
            {lng !== Object.keys(lngs)[Object.keys(lngs).length - 1]
              ? " | "
              : ""}
          </React.Fragment>
        ))}
      </div>

      <p
        className="text-sm text-gray-900"
        dangerouslySetInnerHTML={{ __html: copyright }}
      ></p>
    </footer>
  );
};

export default Footer;
