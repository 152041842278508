import { useTranslation } from "react-i18next";

const ProductFeature = ({ featureNameKey, featureValue }) => {
  const { t } = useTranslation();
  return featureValue ? (
    <tr key={featureNameKey}>
      <td>
        <span className="tracking-widest mr-5">{t(featureNameKey)}</span>
      </td>
      <td>
        <span className="tracking-widest font-semibold">{featureValue}</span>
      </td>
    </tr>
  ) : null;
};

export default ProductFeature;
