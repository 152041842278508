import React, { createContext, useEffect, useState } from "react";
import { getShopSettings } from "../api/WamaClient";

const ShopSettingsContext = createContext({});

const ShopSettingsProvider = ({ children }) => {
  const [shopSettings, setShopSettings] = useState({});

  const getShopIdFromSubdomain = () => {
    const splitUrl = window.location.host.split(".", 2);
    if (splitUrl.length > 1) {
      return splitUrl[0];
    } else {
      return null;
    }
  };

  // const getShopIdFromPath = () => {
  //   const splitUrl = window.location.pathname.split("/", 2);
  //   if (splitUrl.length > 1) {
  //     return splitUrl[1];
  //   } else {
  //     return null;
  //   }
  // };

  useEffect(() => {
    const getSettings = async (shopId) => {
      const respo = await getShopSettings(shopId);
      setShopSettings({ ...respo, shopId: shopId });
    };
    const shopId = getShopIdFromSubdomain();
    if (shopId) {
      getSettings(shopId);
    } else {
      setShopSettings({ shopError: true });
    }
  }, []);

  return (
    <ShopSettingsContext.Provider value={{ shopSettings, setShopSettings }}>
      {children}
    </ShopSettingsContext.Provider>
  );
};

export { ShopSettingsContext, ShopSettingsProvider };
