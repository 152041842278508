import React, { createContext, useEffect, useReducer } from "react";
import { cartReducer, initializer } from "./cartReducer";

const initialState = { lines: [], currency: {} };
// const CartContext = createContext(initialState);
const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialState, initializer);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  // const [state, dispatch] = useReducer((state, action) => {
  //   switch (action.type) {
  //     case "addToCart":
  //       return addToCart(state, action);
  //     case "updateCartQty":
  //       return updateCartQty(state, action);
  //     case "deleteCartEntry":
  //       return deleteCartEntry(state, action);
  //     default:
  //       throw new Error();
  //   }
  // }, initialState);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
