import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FireIcon } from "@heroicons/react/outline";
import MiniCart from "./MiniCart";
import { ShopSettingsContext } from "../../store/ShopSettingsProvider";
import SearchBar from "./SearchBar";

const Header = () => {
  const { shopSettings } = useContext(ShopSettingsContext);

  return (
    <>
      <Helmet>
        <title>
          {shopSettings && shopSettings.topBarTitle
            ? shopSettings.topBarTitle
            : "WAMA Shop"}
        </title>
      </Helmet>
      <nav className="flex flex-wrap justify-between items-center px-4 md:px-8 my-4">
        <Link to="/" className="order-0 text-left lg:w-1/6 w-10/12">
          {shopSettings && shopSettings.topBarTitle ? (
            <span className="font-semibold text-gray-700 text-xl line-clamp-2">
              {shopSettings.topBarTitle}
            </span>
          ) : (
            <FireIcon className="h-9 w-9 text-gray-800" />
          )}
        </Link>

        <SearchBar className="lg:order-1 order-last flex-grow lg:w-4/6 w-full lg:mt-0 mt-4" />

        <MiniCart className="order-2 lg:w-1/6 w-2/12 flex justify-end" />
      </nav>
    </>
  );
};

export default Header;
