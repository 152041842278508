import axios from "axios";
import { NotificationManager } from "react-notifications";

export const searchProducts = async (searchText, shopId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shop/${shopId}/_search/products?page=0&query=${searchText}&size=30&sort=_score,desc`
    );
    return response;
  } catch (error) {
    NotificationManager.error("Try again", "Cannot search products", 3000);
  }
};

export const getCategories = async (shopId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shop/${shopId}/categories?page=0&size=50&sort=name,asc`
    );
    const categoryList = [{ id: null, name: "All products" }];
    return categoryList.concat(response.data);
  } catch (error) {
    NotificationManager.error("Try again", "Cannot search products", 3000);
  }
};

export const getShopSettings = async (shopId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shop/${shopId}/settings`
    );
    return response.data;
  } catch (error) {
    NotificationManager.error("Try again", "Error loading data", 3000);
  }
};

export const getProduct = async (shopId, productId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shop/${shopId}/product/${productId}`
    );
    return response.data;
  } catch (error) {
    NotificationManager.error("Try again", "Error loading product", 3000);
  }
};
