import React, { createContext, useReducer } from "react";
import { categoryReducer } from "./categoryReducer";

const initialState = null;
const CategoryContext = createContext();

const CategoryProvider = ({ children }) => {
  const [category, dispatch] = useReducer(categoryReducer, initialState);

  return (
    <CategoryContext.Provider value={{ category, dispatch }}>
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryProvider };
