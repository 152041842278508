import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

const SearchBar = ({ ...rest }) => {
  const { register, handleSubmit } = useForm();
  const searchForm = useRef();
  const history = useHistory();
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchForm.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };
  const search = (data) => {
    if (data.search && data.search.length) {
      history.push({
        pathname: "/search",
        search: `?term=${data.search}`,
      });
    } else {
      history.push("/");
    }
  };
  return (
    <form
      {...rest}
      ref={searchForm}
      onSubmit={handleSubmit(search)}
    >
      <input
        type="search"
        className="border-2 w-full rounded-md px-3 lg:mx-10 mx-0 h-12	"
        placeholder="Search..."
        onKeyDown={onEnterPress}
        {...register("search")}
      />
    </form>
  );
};

export default SearchBar;
