// todo fix currency
export const getSubtotal = (cart) => {
  return cart.lines
    .reduce(
      (l1, l2) =>
        (typeof l1 === "object" && l1 !== null
          ? Math.round(l1.basePrice * l1.quantity * 100) / 100
          : l1
          ? l1
          : 0) +
        (typeof l2 === "object" && l2 !== null
          ? Math.round(l2.basePrice * l2.quantity * 100) / 100
          : l2
          ? l2
          : 0),
      0
    )
    .toFixed(2);
};

export const getNetPrice = (price) => {
  if (price != null && price.price != null && price.tax != null) {
    if (price.taxIncluded) {
      return price.price / (1 + price.tax.value / 100);
    } else {
      return price.price;
    }
  }
  return price.price;
};

export const getGrossPrice = (price) => {
  if (price != null && price.price != null && price.tax != null) {
    if (price.taxIncluded) {
      return price.price;
    } else {
      return (price.price * (1 + price.tax.value / 100)).toFixed(
        getCurrencyDecimals(price.currrency)
      );
    }
  }
  return price.price;
};

export const getCurrencyDecimals = (currrency) => {
  return currrency && currrency.numDecimals ? currrency.numDecimals : 2;
};

export const getCurrencySymbol = (currrency) => {
  if (currrency) {
    return currrency.symbol ?? currrency.code;
  }
  return "";
};

export const getProductFormattedPrice = (product) => {
  return product.prices && product.prices.length
    ? getFormattedPrice(product.prices[0])
    : "";
};
export const getFormattedPrice = (price) => {
  let formatted = "";
  if (price && price.currency) {
    formatted += getCurrencySymbol(price.currency);
  }
  if (price) {
    formatted += getGrossPrice(price);
  }
  return formatted;
};
export const getCartQuantity = (cart) => {
  return cart.lines.reduce(
    (result, newLine) => result + Number(newLine.quantity),
    0
  );
};
