import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import CartEntry from "../cart/CartEntry";
import { getCurrencySymbol, getSubtotal } from "../utils/CartCalculation";

const CartSummary = () => {
  const { cart } = useContext(CartContext);

  return (
    <div className="leading-loose flex justify-end">
      <div className="lg:max-w-xl w-full m-4 p-6 md:p-10 bg-white rounded shadow">
        <div className=" grid grid-cols-3 gap-4">
          {cart && cart.lines.length ? (
            cart.lines.map((line) => (
              <CartEntry
                key={line.product.id}
                cartEntry={line}
                summary={true}
              />
            ))
          ) : (
            <div className="col-span-3">Your shopping basket is empty.</div>
          )}
        </div>
        <hr className="mt-8 mb-4" />
        <div className="text-right font-bold text-xl">
          Total: {getCurrencySymbol(cart.currency)}
          {getSubtotal(cart)}
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
