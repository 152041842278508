import { ShoppingBagIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProduct } from "../../api/WamaClient";
import { CartContext } from "../../store/CartProvider";
import { ShopSettingsContext } from "../../store/ShopSettingsProvider";
import Button from "../input/Button";
import {
  getGrossPrice,
  getProductFormattedPrice,
} from "../utils/CartCalculation";
import ProductFeature from "./ProductFeature";

const ProductDetail = () => {
  const [product, setProduct] = useState();
  const { productId } = useParams();
  const { shopSettings } = useContext(ShopSettingsContext);
  const { dispatch } = useContext(CartContext);
  const { t } = useTranslation();

  const addToCart = (product) => {
    const line = {
      product: product,
      quantity: 1,
      currency: product.prices[0].currency,
      basePrice: getGrossPrice(product.prices[0]), //todo add vat
    };
    dispatch({ type: "addToCart", value: line });
  };

  useEffect(() => {
    const getProd = async (productId) => {
      setProduct(await getProduct(shopSettings.shopId, productId));
    };

    if (productId && shopSettings && shopSettings.shopId) {
      getProd(productId);
    }
  }, [productId, shopSettings]);

  //https://tailwindcomponents.com/component/product-detail

  return (
    <>
      {product ? (
        <section className="text-gray-700 body-font overflow-hidden bg-white">
          <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
              <img
                alt={product.name}
                className="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200"
                src={"https://wama.cloud/" + product.photo}
              />
              <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                {/* <h2 className="text-sm title-font text-gray-500 tracking-widest">
                  BRAND NAME
                </h2> */}
                <h1 className="text-gray-900 text-3xl text-left font-medium mb-1">
                  {product.name}
                </h1>
                <div className="flex mt-6 items-center pb-5 mb-3">
                  <div className="flex flex-wrap gap-1">
                    {product.categories.map((category) => {
                      return (
                        <button
                          type="button"
                          className="rounded-full px-3 mr-2 bg-gray-400 text-white p-2 rounded leading-none flex items-center"
                        >
                          {category.name}
                        </button>
                      );
                    })}
                  </div>
                </div>

                <p className="leading-relaxed text-left">
                  {product.description}
                </p>

                <div className="mt-6 text-left pb-5 border-b-2 border-gray-200 mb-5">
                  <table className="table-auto border-separate">
                    <ProductFeature
                      featureNameKey="quantity"
                      featureValue={product.quantity}
                    />
                    <ProductFeature
                      featureNameKey="code"
                      featureValue={product.code}
                    />
                    <ProductFeature
                      featureNameKey="barcode"
                      featureValue={product.barcode}
                    />
                    {product.customFields.map((customField) => {
                      return (
                        <tr>
                          <td>
                            <span className="tracking-widest mr-5">
                              {customField.name}:
                            </span>
                          </td>
                          <td>
                            <span className="tracking-widest font-semibold">
                              {customField.value}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="flex">
                  <span className="title-font font-medium text-2xl text-gray-900">
                    {getProductFormattedPrice(product)}
                  </span>

                  <Button
                    className="ml-auto"
                    onClick={() => addToCart(product)}
                  >
                    <ShoppingBagIcon className="w-4 h-4 mr-2"></ShoppingBagIcon>

                    {t("addToCart")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
    // <div>
    //   <h1>{product.name}</h1>
    //   <p>Price: ${product.prices[0].price}</p>
    //   <p>{product.description}</p>
    // </div>
  );
};

export default ProductDetail;
