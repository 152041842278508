const Button = ({
  visible = true,
  type,
  className,
  disabled = false,
  onClick,
  htmlType = "button",
  children,
}) => {
  return (
    <>
      {visible && (
        <button
          className={`disabled:opacity-50 disabled:cursor-not-allowed text-white font-bold py-2 px-4 rounded inline-flex items-center focus:outline-none ${
            type === "secondary"
              ? "bg-gray-400 hover:bg-gray-500 "
              : "bg-blue-500 hover:bg-blue-700 "
          } ${className}`}
          disabled={disabled}
          onClick={onClick}
          type={htmlType}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
