import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import { ShoppingBagIcon, PhotographIcon } from "@heroicons/react/outline";
import {
  getGrossPrice,
  getProductFormattedPrice,
} from "../utils/CartCalculation";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Product = ({ product }) => {
  const { dispatch } = useContext(CartContext);
  const { t } = useTranslation();

  const addToCart = (product) => {
    const line = {
      product: product,
      quantity: 1,
      currency: product.prices[0].currency,
      basePrice: getGrossPrice(product.prices[0]), //todo add vat
      taxPercentage: product.prices[0].tax.value,
    };
    dispatch({ type: "addToCart", value: line });
  };
  return (
    <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
      <NavLink to={`product/${product.id}`}>
        {product.photo ? (
          <img
            className="h-56 w-full object-cover"
            src={"https://wama.cloud/" + product.photo}
            alt={product.name}
          />
        ) : (
          <PhotographIcon className="h-56 w-full p-10 text-gray-400" />
        )}

        <div className="px-4 py-2 h-24 ">
          <h1 className="text-gray-900 text-lg leading-6 paragraph-two-lines">
            {product.name}
          </h1>
          <p className="text-gray-600 text-sm paragraph-two-lines">
            {product.description}
          </p>
        </div>
      </NavLink>
      <div className="flex items-center justify-between px-4 py-2 bg-gray-100">
        <h1 className="text-gray-800 font-bold text-xl mr-2">
          {getProductFormattedPrice(product)}
        </h1>
        <button
          className="px-3 py-1 hover:bg-blue-700 bg-blue-500 text-sm text-white font-semibold rounded flex"
          onClick={() => addToCart(product)}
        >
          <ShoppingBagIcon className="h-4 w-4 self-center mr-1" />
          &nbsp; {t("addToCart")}
        </button>
      </div>
    </div>
  );
};
