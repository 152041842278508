import { Product } from "./Product";
import axios from "axios";
import Button from "../input/Button";
import { useState, useContext, useEffect } from "react";
import { CategoryContext } from "../../store/CategoryProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShopSettingsContext } from "../../store/ShopSettingsProvider";
import { useTranslation } from "react-i18next";
import { searchProducts } from "../../api/WamaClient";

export const Products = () => {
  const { category, dispatch } = useContext(CategoryContext);
  const { shopSettings } = useContext(ShopSettingsContext);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  let params = new URLSearchParams(window.location.search);
  let searchTerm = params.get("term");

  const getProducts = async (pageOverride, search) => {
    const currentPage = pageOverride !== undefined ? pageOverride : page;
    try {
      let response;
      if (search && search.length) {
        response = await searchProducts(search, shopSettings.shopId);
      } else {
        if (shopSettings.shopId) {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/shop/${
              shopSettings.shopId
            }?page=${currentPage}&size=20&sort=name,asc&sort=id&categoryId=${
              category && category.id ? category.id : ""
            }`
          );
        }
      }
      if (!response) {
        return;
      }
      // todo exclude on the BE the products without price, can't count here the products because of pagination
      setCount(response.headers["x-total-count"]);
      const splittedLinks = response.headers.link.split(",");
      const nextPage = splittedLinks
        .filter((link) => link.indexOf("next") >= 0)
        .map((next) =>
          next.substring(next.indexOf("page=") + 5, next.indexOf("&"))
        )[0];
      setPage(nextPage);
      setProducts(
        currentPage === 0 ? response.data : products.concat(response.data)
      );
      setHasMore(nextPage !== undefined);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const refresh = async () => {
    getProducts(0, searchTerm);
  };

  useEffect(() => {
    setPage(0);
    if (shopSettings && shopSettings.shopId) {
      getProducts(0, searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, shopSettings.shopId, searchTerm]);

  return (
    <div className="w-full">
      {products && products.length ? (
        <div className="text-left ml-8 text-gray-700">
          {t("result", { count })}
        </div>
      ) : (
        ""
      )}
      {products && products.length ? (
        <InfiniteScroll
          dataLength={products.length} //This is important field to render the next data
          next={getProducts}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={""}
          // below props only if you need pull down functionality
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: "center" }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
          }
        >
          <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 md:mx-2 lg:mx-1 mx-8">
            {products
              .filter(
                (product) => product.prices.length && product.prices[0].price
              )
              .map((product) => (
                <Product key={product.id} product={product} />
              ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="w-full justify-center mt-20">
          <div className="block text-lg font-semibold">
            {t("noProductsFound")}
          </div>

          <Button
            onClick={() =>
              dispatch({
                type: "selectCategory",
                value: { id: null },
              })
            }
            className="h-12 mt-8 block"
          >
            {t("viewAllProducts")}
          </Button>
        </div>
      )}
    </div>
  );
};
