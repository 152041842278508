import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import React from "react";
import { getCartQuantity, getSubtotal } from "../utils/CartCalculation";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const CartBottom = () => {
  const { cart } = useContext(CartContext);
  return (
    <div className="md:invisible visible fixed bottom-0 left-0 bg-blue-400 text-white w-full font-semibold text-lg">
      {cart && cart.lines.length > 0 ? (
        <Link to="/cart">
          {getCartQuantity(cart)} products £{getSubtotal(cart)}
          <ShoppingCartIcon className="inline-block h-6 w-6 ml-3 mt-1 mb-2" />
        </Link>
      ) : (
        ""
      )}
    </div>
  );
};

export default CartBottom;
