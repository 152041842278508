import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import { Link } from "react-router-dom";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import { getCartQuantity } from "../utils/CartCalculation";

const MiniCart = ({ ...rest }) => {
  const { cart } = useContext(CartContext);
  return (
    <div {...rest}>
      <Link to="/cart">
        <ShoppingBagIcon className="h-9 w-9 text-gray-800" />
        <span className="inline-flex px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
          {getCartQuantity(cart)}
        </span>
      </Link>
    </div>
  );
};

export default MiniCart;
