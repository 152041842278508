export const Input = ({ register, name, label, required, error, ...rest }) => {
  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-first-name"
      >
        {label}
      </label>
      <input
        className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ${
          error ? "border-red-500 focus:border-gray-500" : "border-gray-200"
        }`}
        type="text"
        id={name}
        placeholder={label}
        {...register(name, { required })}
        {...rest}
      />
      {error ? (
        <p className="text-red-500 mb-2  text-xs italic">
          Please fill out this field.
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export const Select = ({
  register,
  name,
  label,
  required,
  error,
  options,
  ...rest
}) => {
  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="relative">
        <select
          id={name}
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
          {...register(name, { required })}
          {...rest}
        >
          <option></option>
          {options
            ? options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              ))
            : ""}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </>
  );
};
