import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          addToCart: "Add to cart",
          completeOrder: "Complete order",
          continueShopping: "Continue shopping",
          checkout: "Checkout",
          cartEmpty: "Your shopping basket is empty.",
          yourShoppingBasket: "Your shoping basket",
          result: "{{count}} result",
          result_plural: "{{count}} results",
          firstName: "Name",
          lastName: "Surname",
          city: "City",
          email: "Email",
          postcode: "Post Code",
          country: "Country",
          line1: "Address",
          phone1: "Phone number",
          notes: "Notes",
          quantity: "Quantity: ",
          code: "Code: ",
          barcode: "Barcode : ",
          copyright: "Copyright &copy; {{year}}",
          viewAllProducts: "View all products",
          noProductsFound: "No products found",
          orderCompleted: "Order completed!",
          yourOrderNumberIs: "Your order number is ",
          contactUs: "Contact us",
        },
      },
      it: {
        translation: {
          addToCart: "Aggiungi al carrello",
          completeOrder: "Completa ordine",
          continueShopping: "Continua gli acquisti",
          checkout: "Vai alla cassa",
          cartEmpty:
            "Continua gli acquisti per aggiungere prodotti al carrello.",
          yourShoppingBasket: "Il tuo carrello",
          result: "{{count}} prodotto",
          result_plural: "{{count}} prodotti",
          firstName: "Nome",
          lastName: "Cognome",
          city: "Città",
          email: "Email",
          postcode: "CAP",
          country: "Nazione",
          line1: "Indirizzo",
          phone1: "Numero di telefono",
          notes: "Notes",
          quantity: "Quantità: ",
          code: "Codice: ",
          copyright: "&copy;{{year}} Tutti i diritti riservati",
          viewAllProducts: "Visualizza tutti i prodotti",
          noProductsFound: "Nessun prodotto trovato",
          orderCompleted: "Ordine completato!",
          yourOrderNumberIs: "Il tuo codice ordine è ",
          contactUs: "Contatti",
        },
      },
    },
  });

export default i18n;
