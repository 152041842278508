import React from "react";
import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import { PhotographIcon, TrashIcon } from "@heroicons/react/outline";
import { getCurrencySymbol } from "../utils/CartCalculation";
import { NavLink } from "react-router-dom";

const CartEntry = ({ cartEntry, summary }) => {
  const globalState = useContext(CartContext);
  const { dispatch } = globalState;
  return (
    <React.Fragment>
      <div className="flex items-center">
        <NavLink to={`product/${cartEntry.product.id}`}>
          <div>
            {cartEntry.product.photo ? (
              <img
                className="w-24 rounded"
                src={"https://wama.cloud/" + cartEntry.product.photo}
                alt={cartEntry.product.name}
              />
            ) : (
              <PhotographIcon className="w-24 rounded md:p-2 p-4 text-gray-400" />
            )}
          </div>
        </NavLink>
      </div>
      <div className="text-left flex items-center md:col-span-1 paragraph-two-lines text-gray-700 hover:text-black">
        <NavLink to={`product/${cartEntry.product.id}`}>
          {cartEntry.product.name}
        </NavLink>
      </div>
      {!summary ? (
        <div className="flex justify-center items-center w-full">
          <input
            type="number"
            value={cartEntry.quantity}
            min="1"
            onChange={(event) =>
              dispatch({
                type: "updateCartQty",
                value: {
                  product: cartEntry.product,
                  quantity: event.target.value,
                },
              })
            }
            className="w-20 appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
          />
        </div>
      ) : (
        ""
      )}

      <div
        className={`hidden flex justify-center items-center ${
          !summary ? "md:inline-flex" : ""
        }`}
      >
        {getCurrencySymbol(cartEntry.product.prices[0].currency)}
        {(Math.round(cartEntry.basePrice * 100) / 100).toFixed(2)}
      </div>

      <div className="font-bold text-lg flex justify-center items-center">
        {getCurrencySymbol(cartEntry.product.prices[0].currency)}
        {(
          Math.round(cartEntry.basePrice * cartEntry.quantity * 100) / 100
        ).toFixed(2)}
      </div>

      <div
        className={`flex items-center justify-end ${summary ? "hidden" : ""}`}
      >
        <button
          onClick={() =>
            dispatch({
              type: "deleteCartEntry",
              value: cartEntry.product,
            })
          }
        >
          <TrashIcon className="text-gray-600 w-7 h-7" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default CartEntry;
