import { useContext } from "react";
import { CartContext } from "../../store/CartProvider";
import { Helmet } from "react-helmet-async";
import CartEntry from "./CartEntry";
import Button from "../input/Button";
import { Link } from "react-router-dom";
import {
  getCartQuantity,
  getCurrencySymbol,
  getSubtotal,
} from "../utils/CartCalculation";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const { cart } = useContext(CartContext);
  const { t } = useTranslation();

  return (
    <div className="flex items-start flex-col px-4 md:px-8 py-2 md:py-8">
      <Helmet>
        <title>Cart</title>
      </Helmet>
      <h1 className="text-xl font-medium mb-8">{t("yourShoppingBasket")}</h1>
      <div className="grid grid-cols-5 md:grid-cols-6 gap-4 md:gap-8 mt-2 md:mt-10 w-full">
        {cart.lines.length ? (
          cart.lines.map((line) => (
            <CartEntry key={line.product.id} cartEntry={line} />
          ))
        ) : (
          <div className="col-span-6">
            <div className="font-semibold mb-4 text-lg">{t("cartEmpty")}</div>
            <Link to="/">
              <Button visible={cart.lines.length === 0}>
                {t("continueShopping")}
              </Button>
            </Link>
          </div>
        )}
      </div>

      {cart.lines.length > 0 && (
        <div className="mt-8 flex justify-end w-full font-bold text-lg justify-end">
          Total: {getCurrencySymbol(cart.currency)}
          {getSubtotal(cart)}
        </div>
      )}

      <div className="flex justify-end mt-10 w-full">
        <Link to="/">
          <Button visible={cart.lines.length > 0} type="secondary">
            {t("continueShopping")}
          </Button>
        </Link>

        <Link to="/checkout" className="ml-4">
          <Button visible={getCartQuantity(cart) > 0}>
            <ShoppingCartIcon className="w-4 h-4 mr-2"></ShoppingCartIcon>
            {t("checkout")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Cart;
