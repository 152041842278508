import { useContext } from "react";
import { Link } from "react-router-dom";
import { ShopSettingsContext } from "../store/ShopSettingsProvider";
import Button from "./input/Button";
const Contacts = () => {
  const { shopSettings } = useContext(ShopSettingsContext);
  console.log(`shopSettings`, shopSettings);
  return (
    <div>
      {shopSettings ? (
        <>
          <div className="whitespace-pre-wrap	font-semibold text-2xl my-28">
            {shopSettings.topBarContactInfo}
          </div>
          <Link to="/" className="block my-10">
            <Button>Home Page</Button>
          </Link>
        </>
      ) : (
        <>
          "No contacts info available"
          <Link to="/">Home Page</Link>
        </>
      )}
    </div>
  );
};

export default Contacts;
