export const initializer = (initialValue) =>
  JSON.parse(localStorage.getItem("cart")) || initialValue;

export const cartReducer = (state, action) => {
  switch (action.type) {
    case "addToCart":
      return addToCart(state, action);
    case "updateCartQty":
      return updateCartQty(state, action);
    case "deleteCartEntry":
      return deleteCartEntry(state, action);
    case "clearCart":
      return clearCart(state, action);
    default:
      throw new Error();
  }
};

const updateCartQty = (state, action) => {
  var productToUpdate = state.lines.find(
    (l) => l.product.id === action.value.product.id
  );
  if (productToUpdate) {
    productToUpdate.quantity = action.value.quantity;
  }
  return {
    ...state,
    lines: [...state.lines],
  };
};

const deleteCartEntry = (state, action) => {
  return {
    ...state,
    lines: state.lines.filter((line) => line.product.id !== action.value.id),
  };
};

const clearCart = (state, action) => {
  return {
    lines: [],
  };
};

const addToCart = (state, action) => {
  const newLines = state.lines.find(
    (line) => line.product.id === action.value.product.id
  )
    ? state.lines.map((line) =>
        line.product.id === action.value.product.id
          ? {
              ...line,
              quantity: line.quantity + action.value.quantity,
            }
          : line
      )
    : [...state.lines, { ...action.value }];

  return {
    ...state,
    currency: newLines[0].currency,
    lines: newLines,
  };
};

// export const addToCart = (item) => ({
//   type: "addToCart",
//   item,
// });

// export const updateCartQty = (item) => ({
//   type: "updateCartQty",
//   item,
// });

// export const deleteCartEntry = (item) => ({
//   type: "deleteCartEntry",
//   item,
// });
