import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/Footer";
import Categories from "./components/category/Categories";
import "./App.css";
import { Products } from "./components/product/Products";
import Contacts from "./components/Contacts";
import Cart from "./components/cart/Cart";
import Checkout from "./components/checkout/Checkout";
import CartBottom from "./components/cart/CartBottom";
import { ShopSettingsContext } from "./store/ShopSettingsProvider";
import { NotificationContainer } from "react-notifications";
import ProductDetail from "./components/product/ProductDetail";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

function App() {
  const { shopSettings } = useContext(ShopSettingsContext);
  
  if (!shopSettings.shopId && !shopSettings.shopError) {
    return (
      <div className="flex w-full h-full items-center justify-center lg:min-h-screen mt-32 lg:-mt-20 flex-col mx-4">
        <Helmet>
          <title>Loading shop...</title>
        </Helmet>

        <img src="https://static.wama.cloud/mail/logo" alt="WAMA" />
        <p className="mt-8 text-gray-700 font-bold text-xl text-center">
          Loading...
        </p>
      </div>
    );
  } else if (shopSettings.shopError || !shopSettings.showSellingPrice) {
    return (
      <div className="flex w-full h-full items-center justify-center lg:min-h-screen mt-32 lg:-mt-20 flex-col mx-4">
        <Helmet>
          <title>WAMA Shop not found</title>
        </Helmet>
        <img src="https://static.wama.cloud/mail/logo" alt="WAMA" />
        <p className="mt-8 text-gray-700 font-bold text-2xl text-center">
          The shop that you are trying to access is not available.
        </p>
        <p className="mt-8 text-gray-900 text-lg text-center">
          Please check the address and try again.
        </p>
      </div>
    );
  } else {
    return (
      <Router>
        <div className="App container mx-auto">
          <NotificationContainer />
          <Header />
          <Route
            path="/"
            exact
            render={() => (
              <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
                <Categories />
                <Products />
              </div>
            )}
          />
          <Route path="/product/:productId">
            <ProductDetail />
          </Route>
          <Route path="/search" component={Products} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          <Footer />
          <CartBottom />
        </div>
      </Router>
    );
  }
}

export default App;
