import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { CartContext } from "../../store/CartProvider";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { CheckIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import OrderNote from "./OrderNote";
import CartSummary from "./CartSummary";
import AddressForm from "./AddressForm";
import axios from "axios";
import Button from "../input/Button";
import { getCartQuantity } from "../utils/CartCalculation";
import { ShopSettingsContext } from "../../store/ShopSettingsProvider";
import { useTranslation } from "react-i18next";
import { SpinnerIcon } from "../common/SpinnerIcon";
import { useForm } from "react-hook-form";
import { pickBy, isEmpty } from "lodash";

const Checkout = () => {
  const { cart, dispatch } = useContext(CartContext);
  const { shopSettings } = useContext(ShopSettingsContext);
  const [order, setOrder] = useState();
  const [isPlacingOrder, setIsPlacingOrder] = useState();
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const createOrder = async (order) => {
    setIsPlacingOrder(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shop/${shopSettings.shopId}/sale-order`,
        order
      );
      setIsPlacingOrder(false);
      setOrder(response.data);
      dispatch({ type: "clearCart" });
    } catch (error) {
      setIsPlacingOrder(false);
      console.error("Error: ", error);
    }
  };

  const placeOrder = async (data) => {
    let customer = undefined;
    if (data.name || data.surname) {
      customer = {
        name: data.name,
        surname: data.surname,
      };
    }
    const strippedEmptyStringsData = pickBy(data, (value) => value.length > 0);
    const deliveryAddress = (({ name, surname, ...d }) => d)(
      strippedEmptyStringsData
    ); // remove name and surname from form data

    /**
     * Remove the product object and add the id into the the field productId
     */
    const linesWithOnlyProductId = cart.lines.map((line) => {
      const lineWithProductId = { ...line, productId: line.product.id };
      const lineWihoutProduct = (({ product, ...o }) => o)(lineWithProductId);
      return lineWihoutProduct;
    });

    const newOrder = {
      orderEntries: linesWithOnlyProductId,
      currencyId: cart.lines[0].currency.id,
      notes: data.note,
      customer: customer,
      deliveryAddress: !isEmpty(deliveryAddress) ? deliveryAddress : undefined,
    };
    await createOrder(newOrder);
  };

  return order || getCartQuantity(cart) > 0 ? (
    <div className="lg:container mx-auto">
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      {!order ? (
        <form onSubmit={handleSubmit(placeOrder)}>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div>
              <AddressForm register={register} errors={errors} />

              <OrderNote register={register} />
            </div>
            <div>
              <CartSummary />
            </div>

            <div>
              <Button
                disabled={isPlacingOrder}
                htmlType="submit"
                className="w-11/12 h-14 text-xl justify-center mx-4"
              >
                <SpinnerIcon visible={isPlacingOrder} />
                {t("completeOrder")}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <div className="mt-8 w-full text-center">
          <CheckIcon className="w-36 text-green-400 inline-block" />

          <div className="text-xl">{t("orderCompleted")}</div>
          <div className="text-md mt-8">
            {t("yourOrderNumberIs")}
            <span className="font-semibold">{order.code}</span>
          </div>
          <div>
            <Link to="/">
              <Button
                className="mt-10 mb-36 text-lg"
                type="primary"
                visible={true}
              >
                <ShoppingBagIcon className="h-9 w-9 w-4 h-4 mr-2" />
                {t("continueShopping")}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Checkout;
