export const categoryReducer = (state, action) => {
  switch (action.type) {
    case "selectCategory":
      return selectCategory(action);
    default:
      throw new Error();
  }
};

const selectCategory = (action) => {
  return action.value;
};
