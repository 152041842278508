import { useTranslation } from "react-i18next";
import { Input, Select } from "../input/FormInputs";

const AddressForm = ({ register, errors }) => {
  const { t } = useTranslation();
  const countries = [
    { key: "fr", value: "France" },
    { key: "de", value: "Germany" },
    { key: "it", value: "Italia" },
    { key: "pt", value: "Portugal" },
    { key: "es", value: "Spain" },
    { key: "gb", value: "United Kingdom" },
    { key: "us", value: "USA" },
  ];
  return (
    <div className="leading-loose max-w-3xl m-4 p-6 lg:p-10 bg-white rounded shadow text-left">
      <div className="flex gap-2">
        <div className="w-1/2">
          <Input
            name="name"
            label={t("firstName")}
            error={errors.name}
            register={register}
            required
          />
        </div>
        <div className="w-1/2">
          <Input
            name="surname"
            label={t("lastName")}
            required
            error={errors.surname}
            register={register}
          />
        </div>
      </div>
      <Input name="email" label={t("email")} register={register} />

      <div className="flex gap-2">
        <div className="w-full md:w-1/2 md:mb-0">
          <Input name="phone1" label={t("phone1")} register={register} />
        </div>

        <div className="w-full md:w-1/2 md:mb-0">
          <Input name="postcode" label={t("postcode")} register={register} />
        </div>
      </div>
      <Input name="line1" label={t("line1")} register={register} />
      {/* flex-wrap */}
      <div className="flex gap-2">
        <div className="w-full md:w-1/2 md:mb-0">
          <Input name="city" label={t("city")} register={register} />
        </div>
        <div className="w-full md:w-1/2 md:mb-0">
          <Select
            name="countryCode"
            label={t("country")}
            register={register}
            options={countries}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
