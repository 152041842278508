import { useState, useEffect, useContext } from "react";
import { CategoryContext } from "../../store/CategoryProvider";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { getCategories } from "../../api/WamaClient";
import { ShopSettingsContext } from "../../store/ShopSettingsProvider";

const Categories = () => {
  const { shopSettings } = useContext(ShopSettingsContext);
  const { category, dispatch } = useContext(CategoryContext);
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setCategories(await getCategories(shopSettings.shopId));
    };
    if (shopSettings && shopSettings.shopId) {
      fetchCategories();
    }
  }, [shopSettings]);

  return (
    <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">
      <div className="flex-shrink-0 px-5 py-4 flex flex-row items-center justify-between">
        <span className="md:hidden text-lg font-semibold tracking-widest text-gray-900 uppercase">
          {category && category.id ? category.name : ""}
        </span>
        <button
          className="rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline text-gray-300"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
            <MenuIcon
              className={`h-9 w-9 text-gray-800 ${isOpen ? "hidden" : ""}`}
            />
            <XIcon
              className={`h-9 w-9 text-gray-800 ${isOpen ? "" : "hidden"}`}
            />
          </svg>
        </button>
      </div>
      <nav
        className={`flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto ${
          isOpen ? "" : "hidden"
        }`}
      >
        {categories
          ? categories.map((cat, index) => (
              <a
                key={index}
                className={`${
                  (category && category.id === cat.id) ||
                  (category === null && cat.id == null)
                    ? `bg-gray-200 dark-mode:bg-gray-700`
                    : `bg-transparent dark-mode:bg-transparent`
                } block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
                href="/#"
                onClick={() => {
                  dispatch({ type: "selectCategory", value: cat });
                  setIsOpen(false);
                }}
              >
                {cat.name}
              </a>
            ))
          : ""}
      </nav>
    </div>
  );
};

export default Categories;
