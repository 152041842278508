import { useTranslation } from "react-i18next";

const OrderNote = ({ register }) => {
  const { t } = useTranslation();
  return (
    <div className="leading-loose">
      <div className="max-w-3xl m-4 p-6 md:p-10 bg-white rounded shadow">
        <div className="text-left">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                {t("notes")}
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="note"
                type="text"
                placeholder={t("notes")}
                name="note"
                {...register("note")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderNote;
